
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        outline: none;
        .glo-editor-modal-content {
            position: relative;
            width: 600px;
            height: 420px;
            background-color: transparent;
        }
        .glo-editor-modal-close {
            position: absolute;
            top: 12px;
            right: 12px;
            color: #fff;
            z-index: 3;
        }
        .sales-bg {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .lowcode-img {
                border-radius: 16px;
                overflow: hidden;
            }
        }
        .glo-editor-modal-body {
            position: relative;
            color: #fff;
            padding: 47px 68px;
            text-align: center;
            font-family: var(--ff-woff2-B);
            background-color: transparent !important;
        }
        @import 'lowcode/common/css/modules/sales-messages.scss';
        @include devices(desktop) {
            .glo-editor-modal-content {
                width: 545px;
                height: 371px;
            }
            .glo-editor-modal-body {
                padding: 37px 58px 0;
            }
        }
        @include devices(pad) {
            .glo-editor-modal-content {
                width: 300px;
                height: 350px;
            }
            .glo-editor-modal-body {
                padding: 34px 40px 0;
            }
            .sales-bg {
                .lowcode-img {
                    border-radius: 10px;
                }
            }
        }
        @include devices(h5) {
            .glo-editor-modal-content {
                width: 300px;
                height: 350px;
            }
            .glo-editor-modal-body {
                padding: 34px 24px 0;
            }
        }
    }
}
