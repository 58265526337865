
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';


.content {
    :global {
        display: block;
    }
}
