
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import 'lowcode/common/css/devices.scss';

.content {
    :global {
        outline: none;
        @import 'lowcode/common/css/modules/sales-messages.scss';
        .glo-editor-modal-content {
            position: relative;
            width: 600px;
            background-color: transparent;
        }
        .glo-editor-modal-close {
            position: absolute;
            top: 12px;
            right: 12px;
            color: #fff;
            z-index: 3;
        }
        .sales-bg {
            width: 100%;
            height: 228px;
            .lowcode-img {
                border-radius: 16px 16px 0 0;
                overflow: hidden;
            }
        }
        .glo-editor-modal-body {
            position: relative;
            color: #fff;
            padding: 40px;
            text-align: center;
            font-family: var(--ff-woff2-B);
            background-color: #fff;
            border-radius: 0 0 4px 4px;
        }
        .sales-messages {
            .sales-title {
                color: #000;
            }
            .sales-desc {
                color: #000;
            }
            .sales-btn.sales-btn-buy {
                background-color: #3F68E0;
                color: #fff;
            }
            .sales-btn.sales-btn-more {
                border-color: #3F68E0;
                color: #3F68E0;
            }
        }
        @include devices(desktop) {
            .glo-editor-modal-content {
                width: 545px;
            }
            .sales-bg {
                height: 211px;
            }
            .glo-editor-modal-body {
                padding: 36px;
            }
        }
        @include devices(pad) {
            .glo-editor-modal-content {
                width: 414px;
            }
            .sales-bg {
                height: 150px;
                .lowcode-img {
                    border-radius: 10px 10px 0 0;
                }
            }
            .glo-editor-modal-body {
                padding: 24px;
            }
        }
        @include devices(h5) {
            .glo-editor-modal-content {
                width: 300px;
            }
            .glo-editor-modal-body {
                padding: 24px;
            }
        }
    }
}
